/* ************************
09.2: Price
********************* */

.price-box {
    @extend .trans3;
    &.active {
        box-shadow: 0px 3px 15px rgba(255, 255, 255, .05);
    }
}

.price-header {
    background-color: #09D1DE;

    &.enterprise {
        background-color: #C491FF;
    }
}

.p_header-img,
.p_header-content {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.p_header-img {
    background-color: rgba(255, 255, 255, .15);
    width: 160px;
    height: 160px;
    align-items: center;
    @include mobileSm {
        width: 140px;
        height: 140px;
    }
}

.p_header-content {
    @extend .text-white;
    flex-direction: column;
    padding-left: 30px;
    @include mobileSm {
        padding-left: 14px;
    }

    h1 {margin-bottom: 3px;}

    h3 {margin-bottom: 5px;}

    h3, p {
        opacity: 0.70;
    }
}

.price-body {
    background-color: #1b2e4b;
    padding: 40px;
    @extend .trans3;

    @include mobileSm {
        padding: 30px 20px;
    }

    h5 {
        text-transform: none;
    }

    ul {
        li {
            padding-left: 24px;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 12px;
            }

            i {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                @extend .text-success;
                
                &.text-danger {
                    @extend .text-danger;
                }
            }
        }
    }
}