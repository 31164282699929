/* ************************
09.7: Miscellaneos
********************* */

.maintenance-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.newsletter-form {
    max-width: 510px;
    margin: 0 auto;
    position: relative;
}

.maintenance-img {
    position: relative;
    margin-left: 120px;
    @include tab {
        margin-left: 0;
    }
}

.maintenance-content {
    padding-top: 176px;
    margin-bottom: 167px;

    @include mobileLg {
        padding-top: 100px;
    }

    @include IE {
        width: 900px;
        margin-left: -400px;
    }
}



/* Session Timeout */
.session-timeout {
    width: 300px;
    @extend .white-bg;
    padding: 40px 20px;
    text-align: center;

    #circular {
        margin: 0 auto;
        display: inline-block;
    }
}

.base-timer {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .base-timer__svg {
    transform: scaleX(-1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 3px;
    stroke: #f5f5f5;
  }
  
  .base-timer__path-remaining {
    stroke-width: 3px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.c2 {
    color: $c2;
  }
  
  .base-timer__path-remaining.warn {
    @extend .text-warning;
  }
  
  .base-timer__path-remaining.danger {
    @extend .text-danger;
  }
  
  .base-timer__label,
  .base-timer__text {
    position: absolute;
    width: 150px;
    height: 150px;
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    @extend .black;
    @extend .bold;
  }

  .base-timer__text {
    font-size: 14px;
    @extend .c4;
    top: 15px;
    text-transform: uppercase;
  }
  
  #modalSessionTimeout {
    .modal-dialog {
        max-width: 300px;
    }
  }