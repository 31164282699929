/* ************************
   02.3: Pagination
   ********************* */

  .pagination {
    ul {
      li {
        @extend .c1;
        @extend .bold;
        @extend .font-14;
        &.current {
          width: 30px;
          height: 30px;
          background-color: #E9E7FF;
          @extend .radius;
          display: flex;
          justify-content: center;
          align-items: center;
          @extend .black;
        }
        a {
          padding: 8px;
        }
        svg {
					path {
						stroke: #fff;
					}
				}
      }
    }

    &.style--two {
		ul {
			margin: 0;
			li {
				svg {
					width: 10px;
					height: 15px;
					path {
						stroke: #E9E7FF;
						@extend .trans3;
					}
				}
			}
		}
    }
  }