/* ************************
   03.4: Main Content
   ********************* */

.main-content {
    min-height: calc(100vh - 190px);
    margin-left: 275px;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-top: 120px;
    @extend .trans3;
    @include tab {
        margin-left: 0;
    }
}

body {
    &.sidebar-folded {
        .main-content {
            margin-left: 75px;
            @include tab {
                margin-left: 0;
            }
        }
    }
}