//Default Colors
$c1: #6045E2;
$c2: #8280FD;
$c3: #67CF94;
$c4: #C4C4C4;
$c5: #f9f9f9;
$text_color: #ffffff;
$white: #ffffff;
$black: #333333;
$card_color: #182335;


// Fonts
$pt_sans: 'PT Sans', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$bolder: 900;