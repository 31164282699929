/* ************************
   01.2: Padding/Margin
   ********************* */
// Paddings
$p-list: 60;
$tab: 60;

// Padding Top
@each $padding in $p-list {
    .pt-#{$padding} {
        padding-top: $padding + px;
        @include tab{
            @if $padding > $tab {
                padding-top: ($padding - $tab) + px;
            } @else {
                padding-top: 0;
            }
        }
    }
}

// Padding Bottom
@each $padding in $p-list {
    .pb-#{$padding} {
        padding-bottom: $padding + px;
        @include tab{
            @if $padding > $tab {
                padding-bottom: ($padding - $tab) + px;
            } @else {
                padding-bottom: 0;
            }
        }
    }
}


$m-list: 40, 30, 20, 10;

// Margin Top
@each $margin in $m-list {
    .mt-#{$margin} {
        margin-top: $margin + px;
    }
}
// Margin Bottom
@each $margin in $m-list {
    .mb-#{$margin} {
        margin-bottom: $margin + px;
    }
}



/* Padding Left Right */
.pl-20 {
    padding-left: 20px;
}
.pr-20 {
    padding-right: 20px;
}
.pb-20 {
    padding-bottom: 20px;
}
.ml-35 {
    margin-left: 35px;
}
.ml-20 {
    margin-left: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
    @include mobileMd {
        padding-top: 20px;
    }
}

.p-30 {
    padding: 30px;
    @include mobileMd {
        padding: 20px 15px;
    }
}

.p-40 {
    padding: 40px;
    @include mobileMd {
        padding: 20px 15px;
    }
}

@include customMq2(767) {
    .mb-md-20 {
        margin-bottom: 20px;
    }
}