/* ************************
09.8: Profile
********************* */

.profile-pic, .cover-img {
    .upload-button {
        background-color: rgba(0, 0, 0, .5);
        @extend .radius-50;
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding: 8px 15px;
        @extend .white;
        z-index: 1;
        width: max-content;

        @include mobileLg {
            padding: 5px 10px;
            right: 10px;
            bottom: 5px;
            font-size: 12px;

            svg {
                width: 16px;
                margin-right: 5px !important;
            }
        }
    
        .file-input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.profile-pic {
    position: relative;
    width: 220px;
    height: 250px;
    margin-top: -164px;
    @include mobileLg {
        width: 120px;
        height: 150px;
        margin-top: -60px;
    }

    .upload-button {
        background-color: rgba(0, 0, 0, .5);
        @extend .radius-50;
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding: 8px 15px;
        @extend .white;
        z-index: 1;

        @include mobileLg {
            padding: 5px 10px;
            right: 0;
            bottom: 5px;
            font-size: 12px;
        }
    
        .file-input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    img {
        z-index: 5;
        border: 6px solid $white;
    }
        
    &:after {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        left: 6px;
        top: 6px;
        position: absolute;
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        @extend .trans3;
    }

    .upload-button {
        opacity: 0;
        @extend .trans3;
        right: 15px;
    }

    &:hover {
        &:after {
            opacity: 1;
        }

        .upload-button {
            opacity: 1;
            right: 20px;
            @include mobileLg {
                right: 5px;
            }
        }
    }
}

.cover-img {
    position: relative;

    &:after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        @extend .trans3;
    }
    .upload-button {
        opacity: 0;
        @extend .trans3;
        bottom: 10px;
    }

    &:hover {
        &:after {
            opacity: 1;
        }

        .upload-button {
            opacity: 1;
            bottom: 20px;
        }
    }
}

.user-profile-nav {
    // padding-left: 260px;
    position: relative;

    .profile-info {
        h3 {
            margin-bottom: 6px;
        }
        p {
            line-height: 1;
        }
    }
}

.profile-nav-tabs {
    align-items: center;
    li {
        a {
            padding: 0px 30px;
            @include mobileLg {
                padding: 0 15px;
            }
        }

        .chat {
            width: 40px;
            height: 40px;
            @extend .radius;
            background-color: #E9E7FF;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .conncetion {
            display: flex;
            align-items: center;
        }
    }
}

.p_nav-link {
    display: block;
    position: relative;
    font-size: 14px;
    @extend .bold;
    @extend .c2;
    line-height: 86px;

    @include customMq(0, 1500) {
        line-height: 60px;
    }

    &.active {
        @extend .bg-3rd;
    }

    &:after {
        position: absolute;
        right: 0;
        width: 3px;
        height: 100%;
        top: 0;
        background-color: #060818;
        content: "";
    }

    &.has-before {
        &:before {
            position: absolute;
            left: 0;
            width: 3px;
            height: 100%;
            top: 0;
            background-color: #060818;
            content: "";
        }
    }

}

.profile-completion {
    background-color: #1b2e4b;
    padding: 20px 30px;

    @include mobileMd {
        padding: 15px;
    }
}

.info-list {
    li {
        margin-bottom: 13px;
        @extend .bold;
        @extend .font-14;
        a {
            @extend .text_color;
        }

        span {
            width: 33%;
            font-weight: 400;
            display: inline-block;
            font-size: 15px;
        }
    }
}

.p_info-list {
    li {
        margin-bottom: 12px;
    }
}

.skill-list {
    li {
        margin-bottom: 24px;
        
        span {
            width: 136px;
            display: inline-block;
        }
    }
}

.todays-evnet {
    ul {
        padding: 26px 0 29px;
        li {
            padding-left: 20px;
            padding-right: 20px;
            border-color: #2b3c57 !important;
            &:not(:first-child) {
                padding-top: 11px;
            }
            &:not(:last-child) {
                padding-bottom: 13px;
            }
            h5 {
                 margin-bottom: 9px;
                 line-height: 1.3;
            }
        }
    }
}

.news-card {
    padding: 25px 0px;

    > a {
        padding-left: 20px;
        padding-right: 20px;
        border-color: #2b3c57 !important;
    }
}

.profile-widget-header {
    padding: 10px 20px;
}


/* About */
.about-tab-content {
    border-left: 1px solid $c4;
    @include mobileLg {
        border: none;
    }
}

.about-nav-tab {

    @include mobileLg {
        border-bottom: 1px solid $c4;
        justify-content: center;
    }

    a {
        display: block;
        margin-bottom: 20px;
        @extend .text_color;

        @include mobileLg {
            padding: 5px 10px;
            margin-bottom: 5px;
            font-weight: 500;
        }


        &.active {
            @extend .c2;
        }
    }
}

.p_overview-list,
.p_work-list,
.p_education-list {
    li {
        margin-bottom: 20px;
        .img {
            width: 32px;
            min-width: 32px;

            img {
                @extend .radius;
            }
        }
        .hover-to-show {
            cursor: pointer;
        }
        // .hover-to-show,
        .dropdown-button {
            opacity: 0;
            @extend .trans3;
            min-width: 64px;
            margin-left: 10px;
            @include mobileLg {
                margin-left: 46px;
                margin-top: 5px;
                opacity: 1;
            }
        }
        .dropdown-button.show {
            opacity: 1;
        }

        &:hover {
            // .hover-to-show,
            .dropdown-button {
                opacity: 1;
            }
        }
    }
}

.iconwrap {
    border: 1px dashed $c2;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .c2;
}

.add-workplace {
    cursor: pointer;
    position: relative;
    z-index: 2;
    display: inline-flex;
    align-items: center;
}

.add-work-form,
.work-update-form {
    position: relative;
    @extend .card_color-bg;
    padding: 10px 5px;
    @extend .trans3;
    z-index: 3;
    .form-control,
    .theme-input-style {
        max-width: 480px;
    }

    .add-work-form-close {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        @include tab {
            top: -20px;
        }
    }
}

.work-content {
    display: flex;
    justify-content: space-between;
    @include mobileMd {
        flex-direction: column;
    }
}

/* User Dashboard */
.project-deadline {
    padding: 21px;
    p {
        color: #CAC6FB;
    }
}

.statistics-bounce-rate {
    background-color: #09D1DE;
    padding: 30px;

    .state-content {
        margin-right: 14px;
        p {
            color: rgba(255, 255, 255, .7);
        }
        h3 {
            font-size: 30px;
            @extend .white;
        }
    }

    &.order {
        background-color: #4F9DF8;
    }
    &.report {
        background-color: #E580FD;
    }
    &.support {
        background-color: #FFB959;
    }
}

.latest-update {
    > a {
        padding: 10px 0;
        @extend .white;
    }
}

/* Edit Profile */
.about-myself {
    textarea {
        line-height: 1.42;
        height: 100px;
        @extend .font-14;
    }
}

.edit-personal-info {
    .form-control {
        height: 30px;
    }
}

.edit-skill {
    .form-control {
        width: 60px;
        margin-right: 10px;
        @extend .font-14;
        height: 30px;
    }

    .process-bar-wrapper {
        .process-bar {
            max-width: 100%;
        }
    }
}

/* News Feed */
.post-controls {
    > div {
        width: 30px;
        height: 30px;
        background-color: #ECF3FD;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .radius;

        &.location {
            margin-right: 30px;
            @include mobileSm {
                margin-right: 16px;
            }
        }
    }
}

.post-body {
    p {
        margin-bottom: 26px;
    }

    .img {
        &.collage {
            .big {
                flex-basis: calc(67% - 20px);
            }
            .small {
                flex-basis: 33%;
            }
        }
    }
}

.post-footer {
    > div {
        cursor: pointer;
    }

    .love {
        svg path {
            @extend .trans3;
        }
        &:hover {
            svg path {
                fill: #E9E7FF;
                stroke: #8280FD;
            }
        }
    }

    > div:not(:last-child) {
        margin-right: 50px;
        @include mobileMd {
            margin-right: 20px;
        }
    }

    > div {
        .icon {
            margin-right: 10px;
        }
        @include mobileMd {
            .icon {
               margin-right: 6px;
               width: 12px;
            }
            .content {
                font-size: 12px;
            }
        }
    }
}

#projectTaskDetailsMain .comments-area {
    padding-top: 30px;
}

.comments-area {
    border-top: 1px solid #F5F5F5;
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .single-comment {
        padding-bottom: 15px;
        margin-bottom: 15px;
        @include mobileSm {
            flex-direction: column;
        }
        .comment-content {
            p {
                margin: 8px 0;
                @extend .white;
            }
        }

        .comment-author-image {
            margin-bottom: 12px;
        }
    }
    ul.children {
        margin-left: 70px;
        @include mobileSm {
            margin-left: 15px;
        }
    }
}